<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("SALES_DELIVERIES.SALES_DELIVERIES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openSalesDeliveryCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_SALES_DELIVERIES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("SALES_DELIVERIES.ADD_SALES_DELIVERY") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_SALES_DELIVERIES)"
              :objectType="'SalesDeliveries'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <sales-delivery-list-table
        @onViewSalesDelivery="openSalesDeliveryViewModal"
        @onEditSalesDelivery="openSalesDeliveryEditModal"
        @onDeleteSalesDelivery="deleteSalesDelivery"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSalesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSalesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_DELIVERY'"
          @onCloseModal="closeSalesDeliveryModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_DELIVERIES.VIEW_SALES_DELIVERY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="
                  () => {
                    salesDeliveryValidateFormModalOpened = true;
                  }
                "
                v-if="
                  openSalesDeliveryLoaded
                    ? openSalesDelivery.items.length > 0 &&
                      openSalesDelivery.status === DELIVERY_STATUS_DRAFT
                    : false
                "
                :title="$t('SALES_DELIVERIES.VALIDATE')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-check-bold"></i>
                </span>
              </base-button>

              <base-button
                type="primary"
                icon
                size="sm"
                @click="viewSalesOrder(openSalesDelivery)"
                v-if="
                  openSalesDeliveryLoaded ? openSalesDelivery.salesOrder : false
                "
                :title="$t('SALES_DELIVERIES.VIEW_ORDER')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-bag-17"></i>
                </span>
              </base-button>

              <base-button
                type="danger"
                icon
                size="sm"
                @click="cancelSalesDelivery(openSalesDelivery)"
                v-if="canCancelSalesDelivery"
                :title="$t('SALES_DELIVERIES.CANCEL')"
              >
                <span class="btn-inner--icon">
                  <i class="ni ni-fat-remove"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openSalesDelivery"
                :objectType="'SalesDeliveries'"
                :objectId="openSalesDelivery.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  v-if="
                    openSalesDelivery.status === DELIVERY_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_EDIT_SALES_DELIVERIES)
                  "
                  class="edit"
                  @click="openSalesDeliveryEditModal(openSalesDelivery)"
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  v-if="
                    openSalesDelivery.status === DELIVERY_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_SALES_DELIVERIES)
                  "
                  class="edit"
                  @click="deleteSalesDelivery(openSalesDelivery)"
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeSalesDeliveryModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-sales-delivery-page
              v-if="openSalesDelivery"
              :salesDeliveryId="openSalesDelivery.id"
              @salesDeliveryLoaded="
                (salesDelivery) => {
                  openSalesDelivery = salesDelivery;
                  openSalesDeliveryLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSalesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSalesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_DELIVERY'"
          @onCloseModal="closeSalesDeliveryModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_DELIVERIES.EDIT_SALES_DELIVERY") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openSalesDeliveryViewModal(openSalesDelivery)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-sales-delivery-page
              v-if="openSalesDelivery"
              :salesDeliveryId="openSalesDelivery.id"
              @onViewSalesDelivery="openSalesDeliveryViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSalesDeliveryModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSalesDeliveryModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_DELIVERY'"
          @onCloseModal="closeSalesDeliveryModal"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("SALES_DELIVERIES.ADD_SALES_DELIVERY") }}
            </h1>
            <button class="close" @click="closeSalesDeliveryModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-sales-delivery-page
              @onViewSalesDelivery="openSalesDeliveryViewModal"
            />
          </div>
        </div>
      </div>
    </div>

    <sales-delivery-view-validate-delivery-form
      v-if="canValidateSalesDelivery"
      :salesDelivery="openSalesDelivery"
      :showModal="salesDeliveryValidateFormModalOpened"
      @closeSalesOrderDeliveryForm="
        () => {
          salesDeliveryValidateFormModalOpened = false;
        }
      "
      @salesDeliveryUpdated="
        () => {
          renderKey++;
        }
      "
    />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  DELIVERY_STATUS_CANCELED,
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
} from "@/constants/deliveries";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesDeliveryListTable from "./partials/SalesDeliveryListTable.vue";
import EditSalesDeliveryPage from "./components/EditSalesDeliveryComponent.vue";
import AddSalesDeliveryPage from "./components/AddSalesDeliveryComponent.vue";
import ViewSalesDeliveryPage from "./components/ViewSalesDeliveryComponent.vue";
import SalesDeliveryViewValidateDeliveryForm from "./partials/SalesDeliveryViewValidateDeliveryForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SalesDeliveryListTable,
    NotificationSubscription,
    EditSalesDeliveryPage,
    AddSalesDeliveryPage,
    ViewSalesDeliveryPage,
    [Button.name]: Button,
    SalesDeliveryViewValidateDeliveryForm,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelSalesDelivery() {
      if (
        this.openSalesDelivery.status === DELIVERY_STATUS_CANCELED ||
        this.openSalesDelivery.status === DELIVERY_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openSalesDelivery.status === DELIVERY_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },

    canValidateSalesDelivery() {
      if (!this.openSalesDeliveryLoaded) {
        return false;
      }
      if (
        this.openSalesDelivery.items.length > 0 &&
        this.openSalesDelivery.status === DELIVERY_STATUS_DRAFT
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSalesDeliveryModalOpened = false;
    let isEditSalesDeliveryModalOpened = false;
    let isAddSalesDeliveryModalOpened = false;
    let openSalesDelivery = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSalesDeliveryModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSalesDeliveryModalOpened = true;
      }
      openSalesDelivery = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSalesDeliveryModalOpened = true;
    }
    return {
      isViewSalesDeliveryModalOpened: isViewSalesDeliveryModalOpened,
      isEditSalesDeliveryModalOpened: isEditSalesDeliveryModalOpened,
      isAddSalesDeliveryModalOpened: isAddSalesDeliveryModalOpened,
      openSalesDelivery: openSalesDelivery,
      openSalesDeliveryLoaded: false,
      renderKey: 1,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_CANCELED: DELIVERY_STATUS_CANCELED,
      DELIVERY_STATUS_VALIDATED: DELIVERY_STATUS_VALIDATED,
      salesDeliveryValidateFormModalOpened: false,
    };
  },

  methods: {
    openSalesDeliveryCreateModal() {
      this.closeSalesDeliveryModal();
      this.isAddSalesDeliveryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesDeliveries",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },

    openSalesDeliveryViewModal(salesDelivery, reRender = false) {
      this.closeSalesDeliveryModal();
      this.openSalesDelivery = salesDelivery;
      this.isViewSalesDeliveryModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesDeliveries",
          query: { id: this.openSalesDelivery.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSalesDeliveryEditModal(salesDelivery) {
      this.closeSalesDeliveryModal();
      this.openSalesDelivery = salesDelivery;
      this.isEditSalesDeliveryModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesDeliveries",
          query: { id: this.openSalesDelivery.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeSalesDeliveryModal() {
      this.isAddSalesDeliveryModalOpened = false;
      this.isViewSalesDeliveryModalOpened = false;
      this.isEditSalesDeliveryModalOpened = false;
      this.openSalesDeliveryLoaded = false;
      this.openSalesDelivery = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesDeliveries",
          query: {},
        }).href
      );
    },

    async deleteSalesDelivery(salesDelivery) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_DELIVERIES.DELETE_THIS_SALES_DELIVERY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "salesDeliveries/destroy",
            salesDelivery.id
          );
          this.renderKey++;
          this.closeSalesDeliveryModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelSalesDelivery(salesDelivery) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_DELIVERIES.CANCEL_SALES_DELIVERY_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("salesDeliveries/cancel", salesDelivery.id);
        this.openSalesDelivery =
          this.$store.getters["salesDeliveries/salesDelivery"];
        this.renderKey++;
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewSalesOrder() {
      this.$router.push(this.$objectViewRoute(this.salesDelivery.salesOrder));
    },
  },
};
</script>
